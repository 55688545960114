import { getStore, setStore } from '../../js/utils/store'
import { loginByMobile, loginByUsername, logout, refreshToken, getUserInfo } from '../../api/loginAndRegister'
import { DialogueContent } from '../../api/websocket.js'
import { getUserMenuByIdentity } from '../../api/add'
import { encryption } from '../../js/utils/util'
import { resetRouter } from '@/router/index'
// import { resolve } from 'core-js/fn/promise'
const user = {
  state: {
    vipLeftTree: getStore({
      name: 'vipLeftTree'
    }) || [],
    userInfo: getStore({
      name: 'userInfo'
    }) || {},
    topSelectAreacode: getStore({
      name: 'topSelectAreacode'
    }) || '',
    menuList: getStore({
      name: 'menuList'
    }) || {},
    permissions: getStore({
      name: 'permissions'
    }) || [],
    identityObj: getStore({
      name: 'identityObj'
    }) || "",
    roles: [],
    expires_in: getStore({
      name: 'expires_in'
    }) || '',
    access_token: getStore({
      name: 'access_token'
    }) || '',
    refresh_token: getStore({
      name: 'refresh_token'
    }) || '',
    AllMessages: getStore({
      name: 'AllMessages'
    }) || '',
    //采集品种
    CollectionVariety: getStore({
      name: 'CollectionVariety'
    }) || [],
    formList: getStore({
      name: 'formList'
    }) || {},
    dispositionList: getStore({
      name: 'dispositionList'
    }) || '',
    ShowRouter: getStore({
      name: 'ShowRouter'
    }) || '',
  },
  actions: {
    SetIdentityObj({ commit, dispatch }, info) {
      if (info) {
        commit('SET_IDENTITY', info)
        return new Promise((resolve, reject) => {
          getUserMenuByIdentity().then(async res => {
            commit('SET_MENU_LIST', res.data.menuList)
            let obj = { ...res.data, ...res.data.identity };
            delete obj.identity;
            delete obj.menuList;
            commit('SET_IDENTITY', obj)
            resolve()
          }).catch(error => {
            reject(error)
          })
        })
      } else {
        commit('SET_IDENTITY', {})
        return;
      }
    },

    // 根据用户名登录
    LoginByUsername({ commit, dispatch }, userInfo) {
      const user = encryption({
        data: userInfo,
        key: "pigxpigxpigxpigx",
        param: ["password"],
      });
      return new Promise((resolve, reject) => {
        loginByUsername(user.username, user.password, user.code, user.randomStr).then(async response => {
          const data = response
          commit('SET_ACCESS_TOKEN', data.access_token)
          sessionStorage.setItem('oldToken', data.access_token)
          commit('SET_REFRESH_TOKEN', data.refresh_token)
          commit('SET_EXPIRES_IN', data.expires_in)
          commit('SET_USER_INFO', data.user_info)
          commit('SET_PERMISSIONS', data.user_info.authorities || [])
          // commit('CLEAR_LOCK')
          // await dispatch('GetUserInfo', data.user_info);
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 根据手机号登录
    LoginByPhone({ commit, dispatch }, userInfo) {
      return new Promise((resolve, reject) => {
        loginByMobile(userInfo.mobile, userInfo.code)
          .then((response) => {
            // const data = response.data
            const data = response;
            commit("SET_ACCESS_TOKEN", data.access_token);
            sessionStorage.setItem("oldToken", data.access_token);
            commit("SET_REFRESH_TOKEN", data.refresh_token);
            commit("SET_EXPIRES_IN", data.expires_in);
            commit("SET_USER_INFO", data.user_info);
            commit("SET_PERMISSIONS", data.user_info.authorities || []);
            // commit('CLEAR_LOCK')
            // dispatch('GetUserInfo', data.user_info);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 刷新token
    RefreshToken({ commit, state }) {
      return new Promise((resolve, reject) => {
        refreshToken(state.refresh_token)
          .then((response) => {
            // const data = response.data
            const data = response;
            commit("SET_ACCESS_TOKEN", data.access_token);
            commit("SET_REFRESH_TOKEN", data.refresh_token);
            commit("SET_EXPIRES_IN", data.expires_in);
            // commit('CLEAR_LOCK')
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 登出
    LogOut({ commit }) {
      resetRouter();
      return new Promise((resolve, reject) => {
        logout()
          .then(() => {
            commit("SET_PERMISSIONS", []);
            commit("SET_IDENTITY", {});
            commit("SET_USER_INFO", {});
            commit("SET_ACCESS_TOKEN", "");
            commit("SET_REFRESH_TOKEN", "");
            commit("SET_EXPIRES_IN", "");
            commit("SET_ROLES", []);
            // commit('CLEAR_LOCK')
            sessionStorage.removeItem("oldToken");
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 注销session
    FedLogOut({ commit }) {
      resetRouter();
      return new Promise((resolve) => {
        commit("SET_PERMISSIONS", []);
        commit("SET_IDENTITY", {});
        commit("SET_USER_INFO", {});
        commit("SET_ACCESS_TOKEN", "");
        commit("SET_REFRESH_TOKEN", "");
        commit("SET_ROLES", []);
        // commit('CLEAR_LOCK')
        resolve();
      });
    },
  },
  mutations: {
    setTopSelectAreacode: (state, arr) => {
      state.topSelectAreacode = arr;
      setStore({
        name: "topSelectAreacode",
        content: arr,
      });
    },
    setVipTree: (state, arr) => {
      state.vipLeftTree = arr;
      setStore({
        name: "vipLeftTree",
        content: state.vipLeftTree,
      });
    },
    SET_ACCESS_TOKEN: (state, access_token) => {
      state.access_token = access_token;
      setStore({
        name: "access_token",
        content: state.access_token,
      });
    },
    SET_EXPIRES_IN: (state, expires_in) => {
      state.expires_in = expires_in;
      setStore({
        name: "expires_in",
        content: state.expires_in,
      });
    },
    SET_REFRESH_TOKEN: (state, rfToken) => {
      state.refresh_token = rfToken;
      setStore({
        name: "refresh_token",
        content: state.refresh_token,
      });
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo;
      setStore({
        name: "userInfo",
        content: userInfo,
      });
    },
    SET_MENU_LIST: (state, menuList) => {
      state.menuList = menuList;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_PERMISSIONS: (state, permissions) => {
      const list = {};
      for (let i = 0; i < permissions.length; i++) {
        list[permissions[i].authority] = true;
      }

      state.permissions = list;
      setStore({
        name: "permissions",
        content: list,
      });
    },
    SET_IDENTITY: (state, identityObj) => {
      if (identityObj) {
        state.identityObj = identityObj
        setStore({
          name: "identityObj",
          content: identityObj,
        });
      }
    },
    //在线沟通未读消息总数
    SET_MESSAGE: (state, AllMessages) => {
      state.AllMessages = AllMessages
      setStore({
        name: 'AllMessages',
        content: AllMessages,
      })
    },
    //是否展示头部和尾部
    // SET_SHOW_ROUTER:(state,ShowRouter)=>{
    //   state.ShowRouter = ShowRouter,
    //   setStore({
    //     name: 'ShowRouter',
    //     content: ShowRouter,
    //   })
    // },
    //采集品种
    SET_TABLElIST: (state, CollectionVariety) => {
      state.CollectionVariety = CollectionVariety
      setStore({
        name: 'CollectionVariety',
        content: CollectionVariety,
      })
    },
    // 采集介绍
    SET_FORMLIST: (state, formList) => {
      state.formList = formList
      setStore({
        name: 'formList',
        content: formList,
      })
    },
    //配置采集表
    SET_DISPOSITION: (state, dispositionList) => {
      state.dispositionList = dispositionList
      setStore({
        name: 'dispositionList',
        content: dispositionList,
      })
    }
  },
};
export default user;
