<template>
  <div id="app">
    <!-- <meta name="viewport" content="width=device-width, initial-scale=1.0"> -->
    <my-header/>
    <router-view ></router-view>
    <my-footer/>
  </div>
</template>
<script>
import myHeader from "./components/header/index";
import myFooter from "./components/footer/index";
import { mapGetters } from "vuex";
// import {selectPrompted} from './api/websocket'
// import { getUserMenuByIdentity } from "@/api/add";
export default {
  components: {
    myHeader,
    myFooter,
  },
  computed: {
    ...mapGetters(["identityObj","vipLeftTree","userInfo"]),
  },
  data: () => ({
    TipsList:{}
  }),

  mounted(){
    // this.open()
    // 登录
    // if (!this.identityObj.identityTypeCode) {
    //     // this.$message.info("请登录后进行操作");
    //     return;
    //   }else{
    //      this.selectPrompted()//系统提示
    //   }
  },
  methods:{

    // open() {
    //   const h = this.$createElement;
    //   this.$msgbox({
    //     title: '系统提示',
    //     message: h('p', null, [
    //       h('span', null, `${this.TipsList.name}你好，您本年度已累计流转土地${this.TipsList.flowAreaMu}亩，根据`),
    //       h('a', {
    //         style: { color: 'teal', cursor: 'pointer' },
    //         on: {
    //           click: () => {
    //             window.open(this.TipsList.articleContent, '_blank'); // 在新窗口中打开链接
    //           }
    //         }
    //       }, `《${this.TipsList.articleTitle}》`),
    //       h('span', null, '规定，您需将流转合同提交有关部门审批，请问是否立即前往提报？')
    //     ]),
    //       showCancelButton: true,
    //       center: true,
    //       confirmButtonText: '去提交审批',
    //       cancelButtonText: '带流转计划完成后统一提交',
    //       cancelButtonClass: 'el-button--info soleClass',
    //       confirmButtonClass: 'el-button--success',
    //       beforeClose: (action, instance, done) => {
    //         if (action === 'confirm') {
    //           instance.confirmButtonLoading = true;
    //           instance.confirmButtonText = '执行中...';
    //           // this.$router.push('/views/lzsp/index')
    //           // this.$router.push('/memberCenter/zyzcgl/assetsApproval')
    //           // this.$router.push({ name: "memberCenter" });
    //           setTimeout(() => {
    //             done();
    //             setTimeout(() => {
    //               instance.confirmButtonLoading = false;

    //             }, 300);
    //           }, 3000);
    //         } else {
    //           done();
    //         }
    //       }
    //     }).then(action => {
    //       this.$router.push({ name: "memberCenter" });
    //       // this.$message({
    //       //   type: 'info',
    //       //   message: 'action: ' + action
    //       // });
    //       this.toPath()
    //     });
    //   },
    //   //系统提示
    //  async selectPrompted(){
    // let res= await selectPrompted()

    //   if(res.code==0){
    //     this.TipsList=res.data
    //     this.open()
    //   }
    // },
    // //跳转的菜单
    // toPath(){
    //     // 会员中心
    //     let arr = this.vipLeftTree;
    //     console.log("arr", arr);
    //     if (!this.userInfo.identityList) {
    //       this.$router.push({ name: "login" });
    //     } else {
    //       if (this.identityObj.identityName && arr.length == 0) {
    //         this.getOthersInfo();
    //       }
    //       if (arr.length > 0) {
    //         if (arr[0].children.length == 0) {
    //           this.$router.push({
    //             path: arr[0].allPath,
    //           });
    //         } else {
    //           if (arr[0].children.length > 0) {
    //             this.$router.push({
    //               path: arr[0].children[0].allPath,
    //             });
    //           }
    //         }
    //       }
    //     }
    // },
    // async getOthersInfo() {
    //   try {
    //     let res = await getUserMenuByIdentity();
    //     // console.log("121241456", res);
    //     if (res.code == 0 && res.data.menuList.length > 0) {
    //       res.data.menuList.forEach((item) => {
    //         if (item.path === "memberCenter") {
    //           let arr = this.transMenu([item][0].children, "/memberCenter");
    //           console.log("1", arr);
    //           if (arr.length > 0) {
    //             this.setVipTree(arr);
    //             this.$router.$avueRouter.formatRoutes(arr, true);
    //             if (arr[0].children.length == 0) {
    //               this.$router.push({
    //                 path: arr[0].allPath,
    //               });
    //             } else {
    //               if (arr[0].children.length > 0) {
    //                 this.$router.push({
    //                   path: arr[0].children[0].allPath,
    //                 });
    //               }
    //             }
    //           }
    //         }
    //       });
    //     }
    //   } catch (error) {
    //     console.log("错误", error);
    //   }
    // },
  }
};
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  /*min-width: 1200px; */
  overflow-y: auto;
    width:auto;
    height:auto;
  .my-custom-cancel-class {
    background-color: red;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
}
  #confirmButtonClass{
    background: #0A9F1A;
  }

}
</style>
